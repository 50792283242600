import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase'; // Import Firestore instance
import './Applicants.css'; // Custom styling
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa'; // React icons for actions
import ApplicantsCount from './ApplicantsCount'; // Import ApplicantsCount component
import RejectedApplicantsCount from './RejectedApplicantsCount'; // Import RejectedApplicantsCount component
import InterviewsCount from './InterviewsCount';
import HiringCount from './HiringCount';
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function Applicants() {
  const [applicants, setApplicants] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [editMode, setEditMode] = useState({}); // To track which rows are in edit mode
  const [editedData, setEditedData] = useState({}); // To store edited data
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const applicantsPerPage = 7; // Number of applicants per page
  const [selectedDay, setSelectedDay] = useState(null); // Define selectedDay state

  useEffect(() => {
    // Set up a real-time listener for applicants collection
    const unsubscribe = onSnapshot(collection(db, 'Applicants'), (snapshot) => {
      const applicantsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setApplicants(applicantsData);
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  // Helper function to format the Firestore timestamp to "MM/DD/YYYY HH:MM:SS"
  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate();
      const formattedDate = date.toLocaleDateString('en-US'); // Format MM/DD/YYYY
      const formattedTime = date.toLocaleTimeString('en-US'); // Format HH:MM:SS
      return `${formattedDate} ${formattedTime}`;
    }
    return 'No Timestamp';
  };

  // Handle expand/collapse for the row
  const toggleExpand = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'Applicants', id));
      toast.success('Applicant deleted successfully!');
    } catch (error) {
      toast.error('Failed to delete applicant.');
    }
  };

  // Enable edit mode for the row
  const enableEditMode = (id, applicant) => {
    setEditMode({ ...editMode, [id]: true });
    setEditedData(applicant); // Populate edited data with current applicant info
  };

  // Handle field changes in edit mode
  const handleFieldChange = (e, field) => {
    setEditedData({
      ...editedData,
      [field]: e.target.value,
    });
  };

  // Save updated applicant data to Firestore
  const saveApplicant = async (id) => {
    await updateDoc(doc(db, 'Applicants', id), {
      firstName: editedData.firstName,
      lastName: editedData.lastName,
      phone: editedData.phone,
      email: editedData.email,
      notes: editedData.notes || '', // Save notes if any
    });
    setEditMode({ ...editMode, [id]: false }); // Disable edit mode
  };

  // Save notes to Firestore
  const saveNotes = async (id) => {
    await updateDoc(doc(db, 'Applicants', id), {
      notes: editedData.notes || '',
    });
    setExpandedRows(expandedRows.filter((rowId) => rowId !== id)); // Collapse the row after saving notes
  };

  // Move applicant to Contacted collection
  const moveToContacted = async (applicant) => {
    try {
      // Add to Contacted collection including notes
      await setDoc(doc(db, 'Contacted', applicant.id), {
        ...applicant, // Spread the existing applicant data
        notes: editedData.notes || applicant.notes || '', // Ensure notes are included
      });

      // Delete from Applicants collection
      await handleDelete(applicant.id);

      // Show success notification
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Contacted!`);
    } catch (error) {
      toast.error('Failed to move applicant to Contacted.');
    }
  };

  // Move applicant to Rejected collection
  const moveToRejected = async (applicant) => {
    try {
      await setDoc(doc(db, 'Rejected', applicant.id), applicant);
      await handleDelete(applicant.id);
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Rejected!`);
    } catch (error) {
      toast.error('Failed to move applicant to Rejected.');
    }
  };

  // Pagination logic
  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = applicants.slice(indexOfFirstApplicant, indexOfLastApplicant);

  const totalPages = Math.ceil(applicants.length / applicantsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="applicants-container">
      <h1>Review Applicants</h1>

      <div className="counter">
        <ApplicantsCount />
        <RejectedApplicantsCount />
        <InterviewsCount selectedDay={selectedDay} />
        <HiringCount />
      </div>

      <table className="applicants-table">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentApplicants.map((applicant) => (
            <React.Fragment key={applicant.id}>
              <tr>
                <td>
                  <button className="expand-btn" onClick={() => toggleExpand(applicant.id)}>
                    {expandedRows.includes(applicant.id) ? '-' : '+'}
                  </button>
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData.firstName}
                      onChange={(e) => handleFieldChange(e, 'firstName')}
                    />
                  ) : (
                    applicant.firstName
                  )}
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData.lastName}
                      onChange={(e) => handleFieldChange(e, 'lastName')}
                    />
                  ) : (
                    applicant.lastName
                  )}
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData.phone}
                      onChange={(e) => handleFieldChange(e, 'phone')}
                    />
                  ) : (
                    applicant.phone
                  )}
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData.email}
                      onChange={(e) => handleFieldChange(e, 'email')}
                    />
                  ) : (
                    applicant.email
                  )}
                </td>
                <td>
                  {applicant.resumeFileURL ? (
                    <a href={applicant.resumeFileURL} target="_blank" rel="noopener noreferrer" className="resume-link">
                      View Resume
                    </a>
                  ) : (
                    'No Resume'
                  )}
                </td>
                <td>{applicant.status || 'No Status'}</td>
                <td>{formatTimestamp(applicant.timestamp)}</td>
                <td className="actions-container">
                  {editMode[applicant.id] ? (
                    <FaCheck
                      className="action-icon row-edit-icon"
                      onClick={() => saveApplicant(applicant.id)}
                      title="Save"
                    />
                  ) : (
                    <FaEdit
                      className="action-icon row-edit-icon"
                      onClick={() => enableEditMode(applicant.id, applicant)}
                      title="Edit"
                    />
                  )}
                  <FaTrash className="action-icon delete-icon" onClick={() => handleDelete(applicant.id)} title="Delete" />
                  <FaCheck className="action-icon accept-icon" onClick={() => moveToContacted(applicant)} title="Contact" />
                  <FaTimes className="action-icon reject-icon" onClick={() => moveToRejected(applicant)} title="Reject" />
                </td>
              </tr>
              {expandedRows.includes(applicant.id) && (
                <tr className="expandable-row">
                  <td colSpan="9">
                    <div className="notes-section">
                      <h4>Notes for {applicant.firstName}</h4>
                      <textarea
                        rows="4"
                        value={editedData.notes || ''}
                        onChange={(e) => handleFieldChange(e, 'notes')}
                        placeholder="Add notes here..."
                      ></textarea>
                      <button className="save-notes-btn" onClick={() => saveNotes(applicant.id)}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Applicants;
