import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase'; // Firestore instance
import { FaEnvelope, FaTimes, FaCalendarAlt, FaCheck } from 'react-icons/fa'; // Icons for actions
import EmailModal from './EmailModal'; // Import the modal component
import Calendar from 'react-calendar'; // Calendar component
import 'react-calendar/dist/Calendar.css'; // Calendar styles
import DatePicker from 'react-datepicker'; // Import Date Picker for scheduling
import 'react-datepicker/dist/react-datepicker.css'; // Date Picker styles
import Modal from 'react-modal'; // Modal for scheduling
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

Modal.setAppElement('#root'); // Set root element for modal accessibility

function Contacted() {
  const [contacted, setContacted] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [selectedApplicantEmail, setSelectedApplicantEmail] = useState('');
  const [selectedApplicantFirstName, setSelectedApplicantFirstName] = useState(''); // Added state for first name
  const [interviewDate, setInterviewDate] = useState(new Date()); // Default date for scheduling interviews
  const [scheduledInterviews, setScheduledInterviews] = useState([]); // For storing scheduled interviews
  const [selectedApplicant, setSelectedApplicant] = useState(null); // For storing selected applicant for interview scheduling
  const [selectedDay, setSelectedDay] = useState(null); // For showing interviews on the selected day
  const [currentPage, setCurrentPage] = useState(1);
  const applicantsPerPage = 5;

  useEffect(() => {
    // Fetch data from Firestore
    const fetchContacted = async () => {
      const querySnapshot = await getDocs(collection(db, 'Contacted'));
      const contactedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setContacted(contactedData);
    };

    fetchContacted();
  }, []);

  useEffect(() => {
    // Fetch scheduled interviews from Firestore
    const fetchScheduledInterviews = async () => {
      const querySnapshot = await getDocs(collection(db, 'ScheduledInterviews'));
      const interviewData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setScheduledInterviews(interviewData);
    };

    fetchScheduledInterviews();
  }, []);

  // Pagination logic
  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentContacted = contacted.slice(indexOfFirstApplicant, indexOfLastApplicant);

  const totalPages = Math.ceil(contacted.length / applicantsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Move applicant to Hiring collection
  const moveToHiring = async (applicant) => {
    try {
      // Move applicant to the Hiring collection
      await setDoc(doc(db, 'Hiring', applicant.id), applicant);
      // Delete the applicant from the Contacted collection
      await deleteDoc(doc(db, 'Contacted', applicant.id));
      // Update state to reflect the change
      setContacted(contacted.filter((item) => item.id !== applicant.id));

      // Show success notification
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Hiring!`);
    } catch (error) {
      // Show error notification
      toast.error('Failed to move applicant to Hiring.');
    }
  };

  // Open the email modal
  const openEmailModal = (applicant) => {
    setSelectedApplicantEmail(applicant.email);
    setSelectedApplicantFirstName(applicant.firstName); // Set first name for the modal
    setIsEmailModalOpen(true);
  };

  // Open the schedule interview modal
  const openScheduleModal = (applicant) => {
    setSelectedApplicant(applicant);
    setIsScheduleModalOpen(true);
  };

  // Schedule an interview
  const scheduleInterview = async () => {
    const interviewDetails = {
      applicantId: selectedApplicant.id,
      applicantName: `${selectedApplicant.firstName} ${selectedApplicant.lastName}`,
      email: selectedApplicant.email,
      interviewDate: interviewDate,
    };

    await setDoc(doc(db, 'ScheduledInterviews', selectedApplicant.id), interviewDetails);
    alert(`Interview scheduled for ${selectedApplicant.firstName} on ${interviewDate.toLocaleDateString()} at ${interviewDate.toLocaleTimeString()}`);

    setScheduledInterviews((prev) => [...prev, interviewDetails]);
    setIsScheduleModalOpen(false);
  };

  // Cancel an interview
  const cancelInterview = async (interviewId) => {
    await deleteDoc(doc(db, 'ScheduledInterviews', interviewId)); // Delete from Firestore
    setScheduledInterviews(scheduledInterviews.filter((interview) => interview.id !== interviewId)); // Update state
    alert('Interview canceled');
  };

  // Handle expand/collapse for the row
  const toggleExpand = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);

      const applicant = contacted.find((applicant) => applicant.id === id);
      if (applicant) {
        setEditedData({
          ...editedData,
          [id]: {
            notes: applicant.notes || '',
          },
        });
      }
    }
  };

  // Handle field changes in edit mode for notes
  const handleFieldChange = (e, id) => {
    setEditedData({
      ...editedData,
      [id]: {
        ...editedData[id],
        notes: e.target.value,
      },
    });
  };

  // Save notes to Firestore
  const saveNotes = async (id) => {
    const updatedNotes = editedData[id]?.notes || '';
    await updateDoc(doc(db, 'Contacted', id), {
      notes: updatedNotes,
    });

    setContacted((prevContacted) =>
      prevContacted.map((applicant) =>
        applicant.id === id ? { ...applicant, notes: updatedNotes } : applicant
      )
    );
    setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
  };

  // Move applicant to Rejected collection
  const moveToRejected = async (applicant) => {
    await setDoc(doc(db, 'Rejected', applicant.id), applicant);
    await deleteDoc(doc(db, 'Contacted', applicant.id));
    setContacted(contacted.filter((item) => item.id !== applicant.id));

    // Show success notification
    toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Rejected!`);
  };

  // Handle sending the email
  const handleSendEmail = (emailData) => {
    console.log('Sending email with data:', emailData);
  };

  // Show interviews scheduled on the clicked day
  const handleDayClick = (value) => {
    setSelectedDay(value);
  };

  return (
    <div className="applicants-container">
      <h1>Contacted Applicants</h1>

      {/* Calendar for Scheduled Interviews */}
      <div className="calendar-interview-container">
        <div className="calendar-container">
          <h2>Scheduled Interviews</h2>
          <Calendar
            onChange={setInterviewDate}
            value={interviewDate}
            onClickDay={handleDayClick}
            tileContent={({ date, view }) =>
              view === 'month' &&
              scheduledInterviews.some(
                (interview) => new Date(interview.interviewDate.seconds * 1000).toLocaleDateString() === date.toLocaleDateString()
              ) ? (
                <div className="scheduled-interview-marker"></div>
              ) : null
            }
          />
        </div>

        {/* Scheduled Interviews for the selected date */}
        <div className="interviews-list-container">
          <h3>Interviews on {selectedDay ? selectedDay.toLocaleDateString() : interviewDate.toLocaleDateString()}:</h3>
          <ul>
            {scheduledInterviews
              .filter(
                (interview) =>
                  new Date(interview.interviewDate.seconds * 1000).toLocaleDateString() ===
                  (selectedDay ? selectedDay.toLocaleDateString() : interviewDate.toLocaleDateString())
              )
              .map((interview) => (
                <li key={interview.id} className="interview-item">
                  {interview.applicantName} at {new Date(interview.interviewDate.seconds * 1000).toLocaleTimeString()}
                  <button
                    className="cancel-btn"
                    onClick={() => cancelInterview(interview.id)}
                    title="Cancel Interview"
                  >
                    Cancel
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <table className="applicants-table">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentContacted.map((applicant) => (
            <React.Fragment key={applicant.id}>
              <tr>
                <td>
                  <button
                    className="expand-btn"
                    onClick={() => toggleExpand(applicant.id)}
                  >
                    {expandedRows.includes(applicant.id) ? '-' : '+'}
                  </button>
                </td>
                <td>{applicant.firstName}</td>
                <td>{applicant.lastName}</td>
                <td>{applicant.phone}</td>
                <td>{applicant.email}</td>
                <td>
                  {applicant.resumeFileURL ? (
                    <a
                      href={applicant.resumeFileURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="resume-link"
                    >
                      View Resume
                    </a>
                  ) : (
                    'No Resume'
                  )}
                </td>
                <td>{applicant.status || 'No Status'}</td>
                <td>{new Date(applicant.timestamp.seconds * 1000).toLocaleDateString()}</td>
                <td className="actions-container">
                  <button
                    title="Send Email"
                    className="action-icon send-mail"
                    onClick={() => openEmailModal(applicant)} // Pass the full applicant object
                  >
                    <FaEnvelope />
                  </button>
                  <button
                    title="Reject Applicant"
                    className="action-icon reject-btn"
                    onClick={() => moveToRejected(applicant)}
                  >
                    <FaTimes />
                  </button>
                  <button
                    title="Schedule Interview"
                    className="action-icon schedule-btn"
                    onClick={() => openScheduleModal(applicant)}
                  >
                    <FaCalendarAlt />
                  </button>
                  <button
                    title="Move to Hiring"
                    className="action-icon accept-icon"
                    onClick={() => moveToHiring(applicant)}
                  >
                    <FaCheck />
                  </button>
                </td>
              </tr>

              {/* Expandable row for notes */}
              {expandedRows.includes(applicant.id) && (
                <tr className="expandable-row">
                  <td colSpan="9">
                    <div className="notes-section">
                      <h4>Notes for {applicant.firstName}</h4>
                      <textarea
                        rows="4"
                        value={editedData[applicant.id]?.notes || ''}
                        onChange={(e) => handleFieldChange(e, applicant.id)}
                        placeholder="Add notes here..."
                      ></textarea>
                      <button className="save-notes-btn" onClick={() => saveNotes(applicant.id)}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      {/* Email Modal */}
      <EmailModal
        isOpen={isEmailModalOpen}
        onRequestClose={() => setIsEmailModalOpen(false)}
        applicantEmail={selectedApplicantEmail}
        applicantFirstName={selectedApplicantFirstName} // Pass the first name
        onSendEmail={handleSendEmail}
      />

      {/* Schedule Interview Modal */}
      <Modal
        isOpen={isScheduleModalOpen}
        onRequestClose={() => setIsScheduleModalOpen(false)}
        contentLabel="Schedule Interview"
        className="schedule-modal custom-modal"
      >
        <h2>Schedule Interview for {selectedApplicant?.firstName}</h2>
        <DatePicker
          selected={interviewDate}
          onChange={(date) => setInterviewDate(date)}
          showTimeSelect
          dateFormat="Pp"
        />
        <button onClick={scheduleInterview}>Schedule</button>
        <button onClick={() => setIsScheduleModalOpen(false)}>Cancel</button>
      </Modal>

      {/* Toast Notification Container */}
      <ToastContainer />
    </div>
  );
}

export default Contacted;
